require ("bootstrap-maxlength/src/bootstrap-maxlength.js");

$(function () {
    //maxLength
    $('.overMaxBreadcrumb').maxlength({
        alwaysShow: true,
        validate: true,
        allowOverMax: false,
        customMaxAttribute: "40",
        warningClass: "badge badge-info",
        limitReachedClass: "badge badge-danger",
        placement: 'top-right-inside'
    });
    $('.overMaxTitle').maxlength({
        alwaysShow: true,
        validate: true,
        allowOverMax: false,
        customMaxAttribute: "85",
        warningClass: "badge badge-info",
        limitReachedClass: "badge badge-danger",
        placement: 'top-right-inside'
    });

    $('.overMaxMetaTitle').maxlength({
        alwaysShow: true,
        validate: true,
        allowOverMax: false,
        customMaxAttribute: "70",
        warningClass: "badge badge-info",
        limitReachedClass: "badge badge-danger",
        placement: 'top-right-inside'
    });
    $('.overMaxMetaDesc').maxlength({
        alwaysShow: true,
        validate: true,
        allowOverMax: false,
        customMaxAttribute: "200",
        warningClass: "badge badge-info",
        limitReachedClass: "badge badge-danger",
        placement: 'top-right-inside'
    });

    
    //Alerts
    setTimeout(() => {
        $('.alert').remove();
    }, 4000);

    //Check if change form
    if (($("form.editor").length > 0)){
        var isChanger = false;
        $('form.editor').on('input propertychange paste', function() {
            isChanger = true;
        });
        window.addEventListener('beforeunload', (event) => {
            if (isChanger) {
                /* TODO */
                //sevent.preventDefault();
                //sevent.returnValue = '';
            }
        });
    }

    //Toggle Card
    $('.card .card-header').on('click', function(){
        $(this).parents('.card').find('.card-body').toggleClass('d-none');
    })

    //UploadMedia
    $.fn.filemanager = function(type, options) {
        type = type || 'file';
        this.on('click', function(e) {
            var route_prefix = (options && options.prefix) ? options.prefix : '/filemanager';
            var target_input = $('input[name="' + $(this).data('input') + '"]');
            var target_preview = $('.preview[preview="' + $(this).data('input') + '"');
            var target_id = $(this).data('input');
            window.open(route_prefix + '?type=' + type, 'FileManager', 'width=900,height=600');
            window.SetUrl = function (items) {
                var file_path = items.map(function (item) {
                    return item.url;
                }).join(',');

                // set the value of the desired input to image url
                target_input.val('').val(file_path).trigger('change');

                // clear previous preview
                target_preview.html('');

                // set or change the preview image src
                items.forEach(function (item) {
                    target_preview.append(
                        $('<img>').attr('src', item.thumb_url)
                    );
                });
                $("#"+target_id+"_clear").show();
            };
            
            return false;
        });
    }

    $('.lfm').filemanager('image', {prefix: "/filemanager"});

    $('.clearImage').click(function(e){
        e.preventDefault();
        $(this).parents('.imageBlock').find('.preview').html("");
        $(this).parents('.imageBlock').find('.inputSource').val("");
        $(this).hide();
    });

    $(document).tooltip({
        items: "[data-tooltip]",
        content: function() {
            var element = $(this);
            if (element.is("[data-tooltip]")) {
                return element.attr("data-tooltip");
            }
        },
        track: true
    });

    //Multi Select
    $('.selectize').selectize({
        plugins: ['remove_button', 'drag_drop']
    });

    //Tagging
    $('.selectizeTags').selectize({
        create: true,
        plugins: ['remove_button']
    });


    $("input.custom-control-input:checkbox").click(function() {
        var idInput=$(this).attr('id');
        
        if($(this).is(":checked")){
            $(this).attr("checked","");
            $('#'+idInput+'_hidden').attr("value",1);
        }else{
            $(this).removeAttr("checked");
            $('#'+idInput+'_hidden').attr("value",0);
        }
    });

    $("input.custom-control-input:checkbox").each(function() {
        var idInput=$(this).attr('id');
        $(this).removeAttr("name");
        var StatusCheckbox = 0;
        if($(this).is(":checked")){
            StatusCheckbox = 1;
        }
        $(this).parent().prepend('<input type="hidden" id="'+idInput+'_hidden" name="'+idInput+'" value="'+StatusCheckbox+'" />');
        

    });
    
})
