import maxlength from "bootstrap-maxlength/src/bootstrap-maxlength.js";

$(function () {
    //maxLength
    $('.overMaxBreadcrumb').maxlength({
        alwaysShow: true,
        validate: true,
        allowOverMax: false,
        customMaxAttribute: "40",
        warningClass: "badge badge-info",
        limitReachedClass: "badge badge-danger",
        placement: 'top-right-inside'
    });
    $('.overMaxTitle').maxlength({
        alwaysShow: true,
        validate: true,
        allowOverMax: false,
        customMaxAttribute: "85",
        warningClass: "badge badge-info",
        limitReachedClass: "badge badge-danger",
        placement: 'top-right-inside'
    });

    $('.overMaxMetaTitle').maxlength({
        alwaysShow: true,
        validate: true,
        allowOverMax: false,
        customMaxAttribute: "70",
        warningClass: "badge badge-info",
        limitReachedClass: "badge badge-danger",
        placement: 'top-right-inside'
    });
    $('.overMaxMetaDesc').maxlength({
        alwaysShow: true,
        validate: true,
        allowOverMax: false,
        customMaxAttribute: "200",
        warningClass: "badge badge-info",
        limitReachedClass: "badge badge-danger",
        placement: 'top-right-inside'
    });

    
    //Alerts
    setTimeout(() => {
        $('.alert').remove();
    }, 4000);

    //Check if change form
    if (($("form.editor").length > 0)){
        var isChanger = false;
        $('form.editor').on('input propertychange paste', function() {
            isChanger = true;
        });
        window.addEventListener('beforeunload', (event) => {
            if (isChanger) {
                /* TODO */
                //sevent.preventDefault();
                //sevent.returnValue = '';
            }
        });
    }

    //Toggle Card
    $('.card .card-header').on('click', function(){
        $(this).parents('.card').find('.card-body').toggleClass('d-none');
    })

    //UploadMedia
    $.fn.filemanager = function(type, options) {
        type = type || 'file';
        this.on('click', function(e) {
            var route_prefix = (options && options.prefix) ? options.prefix : '/filemanager';
            var target_input = $('input[name="' + $(this).data('input') + '"]');
            var target_preview = $('.preview[preview="' + $(this).data('input') + '"');
            var target_id = $(this).data('input');
            window.open(route_prefix + '?type=' + type, 'FileManager', 'width=900,height=600');
            window.SetUrl = function (items) {
                var file_path = items.map(function (item) {
                    return item.url;
                }).join(',');

                // set the value of the desired input to image url
                target_input.val('').val(file_path).trigger('change');

                // clear previous preview
                target_preview.html('');

                // set or change the preview image src
                items.forEach(function (item) {
                    target_preview.append(
                        $('<img>').attr('src', item.thumb_url)
                    );
                });
                $("#"+target_id+"_clear").show();
            };
            
            return false;
        });
    }

    $('.lfm').filemanager('image', {prefix: "/filemanager"});

    $('.clearImage').click(function(e){
        e.preventDefault();
        $(this).parents('.imageBlock').find('.preview').html("");
        $(this).parents('.imageBlock').find('.inputSource').val("");
        $(this).hide();
    });

    $(document).tooltip({
        items: "[data-tooltip]",
        content: function() {
            var element = $(this);
            if (element.is("[data-tooltip]")) {
                return element.attr("data-tooltip");
            }
        },
        track: true
    });

    //Multi Select
    $('.selectize').selectize({
        plugins: ['remove_button', 'drag_drop']
    });

    //Tagging
    $('.selectizeTags').selectize({
        create: true,
        plugins: ['remove_button']
    });


    $("input.custom-control-input:checkbox").click(function() {
        var idInput=$(this).attr('id');
        
        if($(this).is(":checked")){
            $(this).attr("checked","");
            $('#'+idInput+'_hidden').attr("value",1);
        }else{
            $(this).removeAttr("checked");
            $('#'+idInput+'_hidden').attr("value",0);
        }
    });

    $("input.custom-control-input:checkbox").each(function() {
        var idInput=$(this).attr('id');
        $(this).removeAttr("name");
        var StatusCheckbox = 0;
        if($(this).is(":checked")){
            StatusCheckbox = 1;
        }
        $(this).parent().prepend('<input type="hidden" id="'+idInput+'_hidden" name="'+idInput+'" value="'+StatusCheckbox+'" />');
        

    });

    // Color Picker
    $('.colorPicker').spectrum({
        type: "component",
        togglePaletteOnly: true,
        showInput: true,
        showInitial: true,
        showButtons: false,
        localStorageKey: "spectrum.colors",
        palette: [["#000","#444","#666","#999","#ccc","#eee","#f3f3f3","#fff"],
        ["#f00","#f90","#ff0","#0f0","#0ff","#00f","#90f","#f0f"],
        ["#f4cccc","#fce5cd","#fff2cc","#d9ead3","#d0e0e3","#cfe2f3","#d9d2e9","#ead1dc"],
        ["#ea9999","#f9cb9c","#ffe599","#b6d7a8","#a2c4c9","#9fc5e8","#b4a7d6","#d5a6bd"],
        ["#e06666","#f6b26b","#ffd966","#93c47d","#76a5af","#6fa8dc","#8e7cc3","#c27ba0"],
        ["#c00","#e69138","#f1c232","#6aa84f","#45818e","#3d85c6","#674ea7","#a64d79"],
        ["#900","#b45f06","#bf9000","#38761d","#134f5c","#0b5394","#351c75","#741b47"],
        ["#600","#783f04","#7f6000","#274e13","#0c343d","#073763","#20124d","#4c1130"]]
    });

    
})
